<template>
	<div class="policy">
		<div class="container">
			<div class="policy-hread mt-3">
				<div class="policy-hread-box flex">
					<div class="policy-hread-l">
						<div class="swiper-container banner-container position-relative">
							<div class="swiper-wrapper">
								<template v-for="item in bannerData">
									<div class="swiper-slide">
										<div @click="toArticle(item)" class="wh-100 bg-secondary bg-cent" :style="{backgroundImage: 'url(' + item.cover_url + ')'  }">
											<div class="test test-hide">{{item.title}}</div>
										</div>
									</div>
								</template>
							</div>
							<div class="swiper-pagination"></div>
						</div>
					</div>
					<div class="policy-hread-r">
						<div @click="toArticle(bannerData[3])" class="policy-hread-r-top list bg-center" :style="{backgroundImage: 'url(' + bannerData[3].cover_url + ')'  }">
							<div class="test test-hide">{{bannerData[3].title}}</div>
						</div>
						<div @click="toArticle(bannerData[4])" class="policy-hread-r-bottom list mt-2 bg-center" :style="{backgroundImage: 'url(' + bannerData[4].cover_url + ')'  }">
							<div class="test test-hide">{{bannerData[4].title}}</div>
						</div>
					</div>
				</div>
			</div>

			<div class="policy-menu flex justify-between">
				<div class="policy-box">
					<div class="policy-box-title flex align-center">
						<img src="@/assets/image/policy/07005.png" class="mr-1" />
						<h1>热门导航</h1>
					</div>

					<div class="policy-tag flex flex-wrap">
						<div class="tag cursor-pointer mr-1 px-1" :class="item.id == categoryId ? 'tag-ative' : '' " v-for="(item,index) in navTag"
						 :key='index' @click="pikerTag(item.id,'1')">{{item.name}}</div>
					</div>
				</div>

				<div class="policy-box">
					<div class="policy-box-title flex align-center">
						<img src="@/assets/image/policy/07006.png" class="mr-1" />
						<h1>资质标准</h1>
					</div>
					<div class="policy-tag flex flex-wrap">
						<div class="tag cursor-pointer mr-1 px-1" v-for="(item,index) in standardTag" :key='index' @click="navQualifications(item.id)">{{item.name}}</div>
					</div>
				</div>

				<div class="policy-box">
					<div class="policy-box-title flex align-center">
						<img src="@/assets/image/policy/07007.png" class="mr-1" />
						<h1>常见问题</h1>
					</div>
					<div class="policy-tag flex flex-wrap">
						<div class="tag cursor-pointer mr-1 px-1" :class="item.id == categoryId ? 'tag-ative' : '' " v-for="(item,index) in problemTag"
						 :key='index' @click="pikerTag(item.id,'2')">{{item.name}}</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 手机菜单 -->
		<div class="policy-menu-m mt-3 container">
			<div class="tag-box flex justify-between align-center">
				<div class="tag-list" v-for="(item,index) in tagList" :key="index" @click="setIndex(index)" :class="tagIndex == index ? 'active' : ''">{{item}}</div>
			</div>
			<div class="tag-cent flex align-center flex-wrap">
				<div v-if="tagIndex == 0" class="tag cursor-pointer mr-1 px-1" :class="item.id == categoryId ? 'tag-ative' : '' "
				 v-for="(item,index) in navTag" :key='index' @click="pikerTag(item.id,'1')">{{item.name}}</div>
				<div v-if="tagIndex == 1" class="tag cursor-pointer mr-1 px-1" v-for="(item,index) in standardTag" :key='index'
				 @click="navQualifications(item.id)">{{item.name}}</div>
				<div v-if="tagIndex == 2" class="tag cursor-pointer mr-1 px-1" :class="item.id == categoryId ? 'tag-ative' : '' "
				 v-for="(item,index) in problemTag" :key='index' @click="pikerTag(item.id,'2')">{{item.name}}</div>
			</div>

		</div>

		<div class="container">
			<div class="policy-list">
				<div>
					<a-tabs v-model="activeKey" @change="callback">
						<a-tab-pane key="1" tab="政策动态">
							<template v-for="(item, index) in articleList">
								<datalists :item='item' :key='index'></datalists>
							</template>
						</a-tab-pane>
						<a-tab-pane key="2" tab="常见问题" force-render>
							<template v-for="(item, index) in articleList">
								<datalists :item='item' :key='index'></datalists>
							</template>
						</a-tab-pane>
					</a-tabs>
				</div>


				<template v-if="articleList.length == 0">
					<div class="no-case-list py-5">
						<img src="@/assets/image/noDate.png" />
					</div>
				</template>
			</div>
			<div class="policy-page">
				<a-pagination @change="selectPage" hideOnSinglePage :current="page" :pageSize="limit" :total="total" />
			</div>
		</div>
	</div>
</template>

<script>
	import datalists from '@/components/common/data-list.vue'
	import Swiper from "swiper";
	export default {
		components: {
			datalists
		},
		data() {
			return {
				tagIndex: 0,
				tagList: ['热门导航', '资质标准', '常见问题'],
				focusOnSearch: false,
				search: "",
				limit: 8,
				page: 1,
				total: 0,
				articleList: [],
				searchInfoList: null,
				bannerData: [{}, {}, {}, {
					cover_url: '',
					title: ''
				}, {
					cover_url: '',
					title: ''
				}],
				navTag: [],
				standardTag: [],
				problemTag: [],
				categoryId: '',
				tagType: '',
				activeKey: this.$route.query.type || '1',
				flag: 'policy'
			};
		},
		filters: {
			getColor(val) {
				// return 'pink'
				switch (val) {
					case "热门":
						return "red";
						break;
					case "首页":
						return "pink";
						break;
					case "推荐":
						return "orange";
						break;
					case "官方":
						return "red";
						break;
					case "资讯":
						return "green";
						break;
					case "知识":
						return "cyan";
						break;
					case "政策":
						return "blue";
						break;
					case "问题":
						return "purple";
						break;
					case "行业":
						return "blue";
						break;
					case "标准":
						return "blue";
						break;
					default:
						return "blue";
				}
			},
			addNumber(val) {
				return val + Math.floor(Math.random() * 1000) + 100;
			},
		},
		methods: {
			setIndex(index) {
				this.tagIndex = index;
			},
			toArticle(item) {
				this.$router.push({
					name: "article",
					query: {
						id: item.id,
						flag: item.flag || 0,
						except: item.except || 0,
						categoryId: item.category_id || 0,
						professionId: item.profession || 0,
					},
				});
			},
			pikerTag(id, type) {
				console.log(id, type);
				type == 1 ? this.flag = 'policy' : this.flag = 'question';
				this.page = 1;
				this.categoryId = id;
				this.activeKey = type;
				this.getArticleList();
			},
			getCategoryChild(pid) {
				this.$api.getCategoryChild({
						subdoadmin: '',
						pid: pid
					})
					.then((res) => {
						if (pid == 140) {
							this.navTag = res.data;
						} else if (pid == 141) {
							this.standardTag = res.data;
						} else if (pid == 95) {
							this.problemTag = res.data;
						}
					});
			},
			navQualifications(id) {
				this.$router.push('/standard?categoryId=' + id);
			},
			_init_swiper() {
				var swiper = new Swiper(".banner-container", {
					autoplay: 2000,
					loop: false,
					pagination: '.swiper-pagination',
				});
			},
			// tab 选择器
			callback(key) {
				console.log(key);
			},
			closeSearch() {
				this.focusOnSearch = false;
				document.body.style.overflow = "auto";
			},
			showHotList() {
				if (this.$mobile) {
					document.body.style.overflow = "hidden";
				}
				this.focusOnSearch = true;
			},
			searchTest(val) {
				document.body.style.overflow = "auto";
				this.focusOnSearch = false;
				this.search = val;
				this.getArticleList();
			},
			getSearchInfo() {
				this.$api
					.getSearchInfo({
						type: 20,
					})
					.then((res) => {
						this.searchInfoList = res.data;
					});
			},
			getArticleList() {
				this.$api
					.getArticleList({
						except: this.activeKey == 1 ? 'question,criterion,case' : '',
						flag: this.activeKey == 1 ? '' : 'question',
						page: this.page,
						limit: this.limit,
						search: this.search,
						categoryId: this.categoryId
					})
					.then((res) => {
						this.articleList = res.data.list;
						this.total = res.data.total;
					});
			},
			onSearch(val) {
				this.focusOnSearch = false;
				this.search = val;
				this.getArticleList();
			},
			selectPage(index) {
				this.page = index;
				this.getArticleList();
			},
			getBannerData() {
				this.$api
					.getArticleList({
						flag: 'hot',
						page: 0,
						limit: 5,
						search: this.search,
					})
					.then((res) => {
						this.bannerData = res.data.list;
						this.$nextTick(() => {
							this._init_swiper();
						})
					});
			},
		},

		created() {
			this.getArticleList();
			this.getSearchInfo();
			this.getBannerData();

			this.getCategoryChild(140);
			this.getCategoryChild(141);
			this.getCategoryChild(95);
		},
		mounted() {

		}
	};
</script>

<style lang="scss">
	@import "@/assets/css/policy.scss";

	.wh-100 {
		width: 100%;
		height: 100%;
	}

	.policy-hread {
		.policy-hread-box {
			span {
				border: 2px solid #FFFFFF;
			}

			.swiper-slide {
				height: 440px;
				width: 100% !important;
			}

			.swiper-pagination {
				text-align: right;
				z-index: 999;
			}

			.swiper-pagination-bullet {
				width: 12px;
				height: 12px;
				background: #FFFFFF;
				border-radius: 50%;
			}

			.policy-hread .policy-hread-box .swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
				border: 2px solid #FFFFFF;
			}

			.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
				margin: 0 8px;
			}

			.swiper-container-horizontal>.swiper-pagination-bullets,
			.swiper-pagination-custom,
			.swiper-pagination-fraction {
				right: 30px;
			}

			.policy-hread-l {
				width: 785px;
				height: 440px;
				// background: #0000FE;
				margin-right: 16px;
				position: relative;

				.test {
					width: 100%;
					position: absolute;
					bottom: 0;
					left: 0;
					padding: 8px 15px;
					background: rgba(0, 0, 0, 0.6);
					font-size: 20px;
					font-family: Source Han Sans CN;
					font-weight: 500;
					color: #FFFFFF;
					z-index: 9;
				}
			}

			.policy-hread-r {

				// display: flex;
				// flex-direction: row;
				// justify-content: center;
				// align-items: center;
				// flex-wrap: wrap;
				.list {
					width: 379px;
					height: 210px;
					background: #4E3535;
					position: relative;

					.test {
						width: 100%;
						position: absolute;
						bottom: 0;
						left: 0;
						padding: 8px 15px;
						background: rgba(0, 0, 0, 0.6);
						font-size: 20px;
						font-family: Source Han Sans CN;
						font-weight: 500;
						color: #FFFFFF;
					}
				}
			}
		}
	}

	.policy-menu {
		margin: 50px 0;

		.policy-box {
			width: 360px;
			font-size: 20px;
			font-family: Source Han Sans CN;
			font-weight: 500;
			color: #383D41;

			.policy-box-title {
				padding: 12px 0;
				border-bottom: 1px solid #F5F8FA;
				margin-bottom: 15px;

				img {
					// width: 26px;
					// height: 26px;
				}
			}

			.policy-tag {
				margin-top: 15px;
				width: 100%;

				.tag {
					&:hover {
						color: #2070FF;
					}

					margin-top: 15px;
					height: 38px;
					background: #F5F8FA;
					font-size: 14px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					color: #9A9BA4;
					line-height: 38px;
					text-align: center;
					transform: all 0.6s;
				}

				.tag-ative {
					color: #FFFFFF;
					background-color: #2070FF;
					;
				}
			}
		}
	}

	.policy-menu-m {
		display: none;
	}

	@media only screen and (max-width: 1024px) {}

	@media only screen and (max-width: 750px) {}

	@media only screen and (max-width: 481px) {
		.policy {
			.policy-hread {
				margin-top: 10px;

				.policy-hread-box {
					flex-wrap: wrap;

					.policy-hread-l {
						width: 100%;
						margin-right: 0px;
						margin-bottom: 15px;
						height: 200px;

						.test {
							font-size: 12px;
						}
					}

					.swiper-slide {
						height: 200px;
					}

					.policy-hread-r {
						display: flex;
						justify-content: space-between;
						width: 100%;

						.list {
							width: 48%;
							height: 95px;
							margin-top: 0;

							.test {
								font-size: 12px;
							}
						}
					}
				}
			}

			.policy-menu {
				display: none;
			}

			.policy-menu-m {
				display: block;
				border-bottom: 5px solid #F5F8FA;

				.tag-box {
					height: 45px;
					height: 100%;
					font-size: 14px;
					font-family: Source Han Sans CN;
					font-weight: 500;
					color: #383D41;
					line-height: 45px;
					border-bottom: 1px solid #F5F8FA;
				}

				.tag-cent {
					min-height: 127px;
					padding: 10px 0 25px 0;

					.tag {
						margin-top: 15px;
						height: 30px;
						background: #F5F8FA;
						font-size: 14px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						color: #9A9BA4;
						line-height: 30px;
						text-align: center;
					}
				}

				.active {
					// color: #2070ff;
					position: relative;

					&:after {
						content: "";
						display: block;
						width: 30px;
						height: 3px;
						background: #2070ff;
						position: absolute;
						bottom: 0;
						left: 50%;
						transform: translateX(-50%);
					}
				}
			}
		}
	}
</style>
