<template>
	<div :class="ifcolor? 'newdata' : '' " @click="toArticle(item)">
		<div class="data flex cursor-pointer bg-white" >
			<div class="data-l mr-3"> 
				<div class="img bg-cent" :style="{backgroundImage: 'url(' + item.cover_url + ')' }"></div>
			</div>
			<div class="data-r flex-1">
				<div class="test-hide">
					<h1>{{item.title}}</h1>
				</div>
				
				<p class="test-hide-to">{{item.guide}}</p>
				<div class="data-b flex justify-between align-center">
					<div class="data-time flex align-center">
						<img src="@/assets/image/policy/07003.png" />
						<span>{{item.create_time.split(' ')[0]}}</span>
					</div>
					<div class="data-look  flex align-center">
						<img src="@/assets/image/policy/07004.png" />
						<span>{{math}}</span>
					</div>
				</div>
			</div>
		</div>
		<div class="data-line"></div>
	</div>
</template>

<script>
	export default{
		props:{
			item:Object,
			ifcolor:{
				type:String,
				default:''
			}
		},
		data(){
			return{
				math : 100
			}
		},
		methods:{
			toArticle(item) {
				this.$router.push({
					name: "article",
					query: {
						id: item.id,
						flag: item.flag || 0,
						except: item.except || 0,
						categoryId: item.category_id || 0,
						professionId: item.profession || 0,
					},
				});
			},
		},
		created() {
			this.math = Math.round(Math.random()*999+100);
		}
	}
</script>

<style lang="scss" scoped="scoped">
	.data{
		padding: 35px 10px;
		&:hover .data-r p{
			color: #2070FF;
		}
		&:hover .data-l .img{
			transform: scale(1.1,1.1);
		}
		.data-l{
			width: 217px;
			height: 165px;
			background: #E2E2E2;
			overflow: hidden;
			.img{
				transition: all 0.6s;
				width: 100%;
				height: 100%;
			}
		}
		.data-r{
			h1{
				font-size: 20px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #383D41;
				margin-bottom: 20px;
			}
			p{
				font-size: 16px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #9A9BA4;
				margin-bottom: 40px;
			}
			.data-b{
				span{
					margin-left: 15px;
					font-size: 16px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					color: #AFB0B7;
				}
			}
		}
	}
	
	.data-line{
		height: 1px;
		background: #F5F8FA;
	}
	.newdata .data-line{
		height: 20px;
	}
	.newdata .data{
		padding-right: 30px;
	}
	
	@media only screen and (max-width: 1024px) {}
	
	@media only screen and (max-width: 750px) {}
	
	@media only screen and (max-width: 481px) {
		.data{
			width: 100%;
			padding: 20px 0px;
			.data-l{
				width: 130px;
				height: 99px;
				margin-right: 10px;
				.img{
					
				}
			} 
			.data-r{
				width: 100%;
				h1{
					margin-bottom: 10px; 
					font-size: 15px;
				}
				p{
					margin-bottom: 15px;
					font-size: 12px;
				}
				.data-b{
					img{
						width: 12px;
					}
					span{
						font-size: 11px;
					}
				} 
			}
		} 
	}
</style>
